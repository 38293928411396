body {
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
}

.airtable-embed {
    background: transparent;
    border: 1px solid #ccc;
}

.navigation-bar {
    display: flex;
    flex-direction: row;
    background: #f44336;
    border-right: 1px solid #ff0000;
    left: 0;
    overflow-x: hidden;
    padding: 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    a {
        text-decoration: none;
        color: #fff;
    }
    .home {
        font-size: 35pt;
    }

    .menu {
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 25px;
        margin: auto 0;
        font-size: 1.3vw;

        .item {
            margin: 0 10px;
        }
    }
    .noauth {
        font-size: 1.5vw;
    }
    .protected-link {
        color: yellow;
    }

    @media (max-width: 1300px) {
        .menu {
            font-size: 2vw;
        }
        .noauth {
            font-size: 2.3vw;
        }
    }
    @media (max-width: 1000px) {
        justify-content: center;
        .home {
            margin-top: 20px;
        }
        .menu {
            font-size: 2.2vw;
        }
        .noauth {
            font-size: 2.5vw;
        }

    }
    @media (max-width: 815px) {
        .home {
            margin-top: 25px;
        }
        .menu {
            font-size: 2.5vw;
        }
        .noauth {
            font-size: 3vw;
        }
    }
    @media (max-width: 400px) {
        .home {
            font-size: 30pt;
        }
        .menu {
            font-size: 2.25vw;
        }
    }
}

.page {
    padding: 20px;
    margin: 65px auto;
    width: 90%;
}
@media (max-width: 1000px) {
    .page {
        padding: 0px;
        margin-top: 120px;
        width: 100%;
    }
}

.subtitle {
    color: #878787;
    font-size: 16pt;
}
.email {
    margin: 20px 0 20px 0;
}

.landing {
    text-align: left;
    margin: auto;
    width: 80%;
    .subtitle {
        margin-bottom: 20px;
    }
    .basket {
        max-height: 250px;
        margin-left: 15px;
        @media (max-width: 700px) {
            max-width: 200px;
        }
    }

    .section {
        margin: 5px 0;

        &.image {
            display: flex;
            flex-direction: row;
            @media (max-width: 700px) {
                flex-direction: column;
            }
        }

        .section-title {
            font-size: 12pt;
            font-weight: bold;
        }

        .section-content {
            font-size: 11pt;
        }
    }
}

.internal-links {
    .section {
        margin: 25px 0;

        .section-title {
            font-size: 14pt;
        }

        .section-content {
            font-size: 11pt;
        }
    }
}

@media (max-width: 400px) {
    .landing {
        width: 90%;
        margin: auto;
    }
}
.resources-page {
    display: flex;
    flex-direction: row;
    font-size: 11pt;

    @media (max-width: 700px) {
        flex-direction: column;
    }

    .title {
        font-size: 17pt;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .subtitle {
        font-size: 14pt;
        margin-bottom: 10px;
    }
    .heavy-lifting {
        max-height: 400px;
    }
}

.contact-page {
    display: flex;
    flex-direction: row;

    .contact-container {
        margin: 10px auto 20px auto;
        padding: 10px;
        border: 1px solid #ff0000;
        height: 110%;
        order: 1;
        width: 40%;

        div {
            margin-bottom: 5px;
        }

        .subtitle {
            font-size: 14pt;
            margin-bottom: 10px;
        }

        @media (max-width: 1650px) {
            width: 60%;
        }
        @media (max-width: 1200px) {
            width: 70%;
        }
        @media (max-width: 700px) {
            width: 80%;
        }
        @media (max-width: 550px) {
            width: 90%;
        }
        @media (max-width: 500px) {
            width: 95%;
        }
    }

    .smiley-logo {
        padding: 10px;
        max-height: 166px;
    }
    
}

.auth-button {
    box-shadow: inset 0px 1px 0px 0px #a4e271;
    background-color: #f44336;
    border-radius: 6px;
    border: 1px solid white;
    display: inline-block;
    cursor: pointer;
    color: white !important;
    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    padding: 6px 24px;
    text-decoration: none;
    margin: 10px;

    img {
        width: 20px;
        height: 10px;
    }
    &:hover {
        background-color: #f1392c;
    }
    &:active {
        position: relative;
        top: 1px;
    }
}
